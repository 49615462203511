import React from "react";
import { Container } from "../../../helpers/GlobalStyle";
import Typography from "./../../atoms/Typography";
import { useParams } from "react-router-dom";
import Terms from "./Terms";
import Faq from "./Faq";

const ContactUs = () => {
  return (
    <Container style={{ paddingTop: 50 }}>
      <Typography variant="h1">Contact Us</Typography>
      <Typography variant="p2">
        You can send us an email on{" "}
        <a href="mailto:hello@owosh.com">hello@owosh.com</a> Or send a whatsapp
        message to <a href="tel:+2347079631336">+234 707 963 1336</a>
      </Typography>
    </Container>
  );
};

const Pages = () => {
  let { url } = useParams();
  return (
    <Container style={{ paddingTop: 50 }}>
      {url === "contact-us" && <ContactUs />}
      {url === "terms-of-use" && <Terms />}
      {url === "faqs" && <Faq />}
    </Container>
  );
};

export default Pages;
