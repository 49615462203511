import React from "react";
import { Container } from "../../../helpers/GlobalStyle";
import Typography from "../../atoms/Typography";

const Faq = () => {
  return (
    <Container style={{ paddingTop: 50 }}>
      <Typography variant="h1">Frequently Asked Questions</Typography>
      <Typography variant="h4">1. What is Owosh?</Typography>
      <Typography variant="p2">
        Owosh is a movie streaming app that allows you to rent and stream movies
        on a pay-per-view basis.
      </Typography>
      <Typography variant="h4">2. How does it work?</Typography>
      <Typography variant="p2">
        Simply browse our selection of movies, choose the one you want to watch,
        and pay the rental fee. You'll then have access to the movie for a
        limited time (typically 5 days).
      </Typography>
      <Typography variant="h4">
        3. What devices can I use to watch movies?
      </Typography>
      <Typography variant="p2">
        Our app is available on iOS, Android, smart TVs, and can also be
        streamed directly from our website/
      </Typography>
      <Typography variant="h3">Payment and Rental Questions</Typography>
      <Typography variant="h4">
        1. How much does it cost to rent a movie?
      </Typography>
      <Typography variant="p2">
        Rental fees vary depending on the movie and the geography. The price of
        each movie is written on the movie card
      </Typography>
      <Typography variant="h4">
        2. What payment methods do you accept?
      </Typography>
      <Typography variant="p2">
        We accept payments through credit/debit cards, bank transfers, mobile
        money and other forms of online payments
      </Typography>
      <Typography variant="h4">
        3. How long do I have to watch a rented movie?
      </Typography>
      <Typography variant="p2">
        Typically, you'll have 5 days to watch a rented movie from the time of
        rental.
      </Typography>
      <Typography variant="h4">4. Can I extend the rental period?</Typography>
      <Typography variant="p2">
        No, the rental period is fixed and cannot be extended. After the five
        days , you will need to rent it again.
      </Typography>
      <Typography variant="h3">Account and Login Questions</Typography>
      <Typography variant="h4">
        1. Do I need to create an account to use the platform?
      </Typography>
      <Typography variant="p2">
        Yes, you'll need to create an account to rent and watch movies.
      </Typography>
      <Typography variant="h4">
        2. I forgot my password. How do I reset it?
      </Typography>
      <Typography variant="p2">
        You can reset your password by tapping "Forgot Password" on the login
        screen.
      </Typography>
      <Typography variant="h4">
        3. Can I share my account with others?
      </Typography>
      <Typography variant="p2">
        No, accounts are for individual use only and cannot be shared with
        others.
      </Typography>
      <Typography variant="h3">Refunds and Cancellations</Typography>
      <Typography variant="h4">
        1. Can I get a refund if I don't like the movie?
      </Typography>
      <Typography variant="p2">
        All rentals are final and non-refundable. However, if you experience
        technical issues with the movie, we may provide a refund or replacement.
      </Typography>
      <Typography variant="h3">Other Questions</Typography>
      <Typography variant="h4">
        1. Can I get a refund if I don't like the movie?
      </Typography>
      <Typography variant="p2">
        All rentals are final and non-refundable. However, if you experience
        technical issues with the movie, we may provide a refund or replacement.
      </Typography>
      <Typography variant="h4">
        1. Is my personal and payment information secure?
      </Typography>
      <Typography variant="p2">
        Yes, We have no access to your payment information, your payment is
        processed by a third party (Flutterwave ), Flutterwave meets or exceeds
        global security standards and is PCI-DSS certified. Flutterwave uses
        advanced encryption, multi-layered authentication, and regular
        transaction monitoring to protect card payments.
      </Typography>

      <Typography variant="h4">
        2. How do I report a problem with the app?
      </Typography>
      <Typography variant="p2">
        You can report a problem with the app by sending an email to us on
        hello@owosh.com.
      </Typography>
    </Container>
  );
};

export default Faq;
