import React from "react";
import { Container } from "../../../helpers/GlobalStyle";
import Typography from "./../../atoms/Typography";

const Terms = () => {
  return (
    <Container style={{ paddingTop: 50 }}>
      <Typography variant="h1">Terms of Use</Typography>
      <Typography variant="h3">Introduction</Typography>
      <Typography variant="p2">
        Welcome to Owosh Films Ltd, ("we," "us," or "our"). These Terms of Use
        govern your use of our website and mobile application (collectively, the
        "Service").
      </Typography>
      <Typography variant="h3">Acceptance of Terms</Typography>
      <Typography variant="p2">
        By using the Service, you agree to be bound by these Terms of Use. If
        you do not agree to these Terms of Use, please do not use the Service.
      </Typography>
      <Typography variant="h3">Service Description</Typography>
      <Typography variant="p2">
        The Service allows you to rent movies on a pay-per-view basis. Once you
        rent a movie, you will have access to it for 5 days from the date of
        rental.
      </Typography>
      <Typography variant="h3">Rental Terms</Typography>
      <ol>
        <li>
          Rental Period: The rental period for each movie is 5 days from the
          date of rental.
        </li>
        <li>
          Rental Fee: The rental fee for each movie is as stated on the movie
          you want to rent.
        </li>
        <li>
          Payment: You must fund your wallet on the website using a valid
          payment method accepted by us.
        </li>
        <li>
          Access: Once you rent a movie, you will have access to it through your
          account on the Service.
        </li>
      </ol>
      <Typography variant="h3"> Use of the Service</Typography>
      <ol>
        <li>
          Personal Use: The Service is for personal, non-commercial use only.
        </li>
        <li>
          Prohibited Activities: You must not: - Copy, reproduce, distribute, or
          display any content from the Service without our prior written
          consent. - Use any automated means to access, monitor, or copy any
          content from the Service. - Interfere with or disrupt the operation of
          the Service.
        </li>
        <li>
          Account Security: You are responsible for maintaining the security of
          your account and password.
        </li>
      </ol>
      <Typography variant="h3">Intellectual Property Rights</Typography>
      <Typography variant="p2">
        All content available on the Service, including movies, images, and
        text, is protected by intellectual property rights. You must not
        infringe on these rights.
      </Typography>
      <Typography variant="h3">Disclaimer of Warranties</Typography>
      <Typography variant="p2">
        The Service is provided on an "as is" and "as available" basis. We
        disclaim all warranties, express or implied, including the implied
        warranties of merchantability, fitness for a particular purpose, and
        non-infringement
      </Typography>
      <Typography variant="h3">Limitation of Liability</Typography>
      <Typography variant="p2">
        In no event shall we be liable for any direct, indirect, incidental,
        special, or consequential damages arising out of or related to the
        Service.
      </Typography>
      <Typography variant="h3">Termination</Typography>
      <Typography variant="p2">
        We may terminate or suspend your access to the Service at any time,
        without notice, for any reason.
      </Typography>
      <Typography variant="h3">Governing Law</Typography>
      <Typography variant="p2">
        These Terms of Use shall be governed by and construed in accordance with
        the laws of [Lagos/Nigeria].
      </Typography>
      <Typography variant="h3">Changes to Terms</Typography>
      <Typography variant="p2">
        We may update these Terms of Use from time to time. We will notify you
        of any changes by posting the updated Terms of Use on the Service.
      </Typography>

      <Typography variant="h3">Contact Us</Typography>
      <Typography variant="p2">
        If you have any questions or concerns about these Terms of Use, please
        contact us at hello@owosh.com. By using the Service, you acknowledge
        that you have read, understood, and agree to be bound by these Terms of
        Use.
      </Typography>
      <Typography variant="h3">Definitions</Typography>
      <Typography variant="p2">
        For the purposes of these Terms of Use, the following definitions apply:
        <ul>
          <li>
            "Content" means any movies, images, text, or other materials
            available on the Service.
          </li>
          <li>
            "Rental Fee" means the fee payable by you to rent a movie on the
            Service.
          </li>
          <li>
            "Rental Period" means the period during which you have access to a
            rented movie, as stated in these Terms of Use.
          </li>
          <li>
            "Service" means the movie streaming website and mobile application
            provided by us
          </li>
        </ul>
      </Typography>
    </Container>
  );
};

export default Terms;
